import { useCallback, useEffect, useState } from "react";
import Router, { useRouter } from "next/router";

const emptyHash = ["", ""];

const getHash = (value) => {
  const [, hash] =
    value.match(/#(.*)[?]/) || value.match(/#(.*)[?]?/) || emptyHash;
  return hash;
};

const options = {
  scroll: false,
};

export default function useHash() {
  const { query, asPath, push, replace } = useRouter();
  const [value, setValue] = useState(() => getHash(asPath));

  useEffect(() => {
    const handle = (v) => setValue(getHash(v));

    Router.events.on("routeChangeComplete", handle);
    Router.events.on("hashChangeComplete", handle);

    return () => {
      Router.events.off("routeChangeComplete", handle);
      Router.events.off("hashChangeComplete", handle);
    };
  }, []);

  const onChange = useCallback(
    (hash) => push({ hash, query }, undefined, options),
    [push, query]
  );

  const onSilentChange = useCallback(
    (hash) => replace({ hash, query }, undefined, options),
    [replace, query]
  );

  return [value, onChange, onSilentChange];
}
